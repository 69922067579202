import {
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  SimpleGrid,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { slsFetch } from "@klaudsol/commons/lib/Client";
import { APPROVED_STATUS } from "lib/Constants";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { FaFacebookF, FaInstagramSquare, FaTwitter } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { useAppDispatch } from "src/app/hooks";
import ThirstLogo from "src/assets/icons/thirst-logo.png";
import { useRootContext } from "src/contexts/RootContext";

const StoreFooter = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const rootContext = useRootContext();
  const _categories = rootContext?.categories;
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    getAvailableDeliveryAreas();
  }, []);

  const getAvailableDeliveryAreas = async () => {
    try {
      let response = await slsFetch(
        `/api/stores/getStoreDeliveryAreas?status=${APPROVED_STATUS}`,
        {
          method: "GET",
          headers: { "Content-type": "application/json" },
        }
      );

      const { data } = await response.json();

      const cities = [];
      data.map((i) => {
        const availableDeliveryAreas = JSON.parse(
          i.delivers_to_cities
        ).deliveryArea;
        availableDeliveryAreas.map((city) => {
          if (cities.includes(city.label) === false) cities.push(city.label);
        });
      });
      setCityList(cities);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VStack maxW="100%" spacing="0px" px={["12px", 0]}>
      <Divider />

      <Box placeSelf="flex-start" pt="34px" px={0}>
        <Image
          src={ThirstLogo.src}
          w={["126.27px", "200px"]}
          h={["50px", "80px"]}
          alt="thirst-logo"
        />
      </Box>

      <Text
        pt="24px"
        fontSize={["12px", "12px", "12px", "12px", "14px"]}
        fontWeight={500}
        fontFamily="CeraProLight"
        color="#214151"
        placeSelf="flex-start"
      >
        Thirst – Liquor on Demand is the only Online Liquor Marketplace in the
        country. Get all your liquor needs met on one website. Select from
        multiple product listings, compare prices and choose from multiple
        merchants listed on Thirst and get your order delivered right to your
        doorstep. #GetThirsty and #IndulgeYourThirst today!
      </Text>
      <Text
        fontSize={["12px", "12px", "12px", "12px", "14px"]}
        fontWeight={500}
        pt={5}
        fontFamily="CeraProLight"
        color="#214151"
        textAlign="left"
        placeSelf="flex-start"
      >
        Drink responsibly, 18+ only.
      </Text>

      <Text
        fontSize="14px"
        fontWeight={500}
        fontFamily="CeraPro"
        color="#117BD4"
        placeSelf="flex-start"
      >
        <Link
          href="/merchant/register"
          onClick={() => {
            ReactGA.event({
              category: "Merchant Register",
              action: "Merchant Register Button Click",
            });
          }}
          _focus={{ bg: "white", textDecoration: "none" }}
          _active={{ bg: "white", textDecoration: "none" }}
          _hover={{ bg: "white", textDecoration: "none" }}
        >
          Want to have a store on Thirst? Register now!
        </Link>
      </Text>

      <HStack w="100%" pt="28px">
        <Link href="https://www.facebook.com/IndulgeYourThirst" isExternal>
          <IconButton
            icon={
              <Icon as={FaFacebookF} boxSize={[6, 6, 6, 8]} color="#117BD4" />
            }
            bg="white"
            _hover={{ bg: "white", border: "none" }}
            _active={{ bg: "white", border: "none" }}
            _focus={{ bg: "white", border: "none" }}
          />
        </Link>

        <Link href="https://twitter.com/thirst_ph" isExternal>
          <IconButton
            icon={
              <Icon as={FaTwitter} boxSize={[6, 6, 6, 8]} color="#117BD4" />
            }
            bg="white"
            _hover={{ bg: "white", border: "none" }}
            _active={{ bg: "white", border: "none" }}
            _focus={{ bg: "white", border: "none" }}
          />
        </Link>

        <Link
          href="https://instagram.com/thirst_ph?igshid=YmMyMTA2M2Y="
          isExternal
        >
          <IconButton
            icon={
              <Icon
                as={FaInstagramSquare}
                boxSize={[6, 6, 6, 8]}
                color="#117BD4"
              />
            }
            bg="white"
            _hover={{ bg: "white", border: "none" }}
            _active={{ bg: "white", border: "none" }}
            _focus={{ bg: "white", border: "none" }}
          />
        </Link>
      </HStack>

      <VStack w="100%" pt="40px" spacing="5px">
        <Text
          fontWeight={500}
          fontFamily="CeraPro"
          fontSize="16px"
          color="#98A6AD"
          w="100%"
          textAlign="left"
          pb="0px"
        >
          Categories
        </Text>

        <SimpleGrid
          columns={[2, 8]}
          spacingX={10}
          spacingY={2}
          placeSelf="flex-start"
        >
          {_categories
            ?.slice()
            .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
            .map((i, index) => {
              return (
                <Link
                  key={i.name}
                  href={`/${i.slug}`}
                  fontWeight={500}
                  fontFamily="CeraPro"
                  textDecoration="none"
                  placeSelf="flex-start"
                  fontSize="14px"
                  maxW={["120px", "200px"]}
                  color="#214151"
                  _focus={{
                    color: "#117BD4",
                    bg: "white",
                    textDecoration: "none",
                  }}
                  _active={{
                    color: "#117BD4",
                    bg: "white",
                    textDecoration: "none",
                  }}
                  _hover={{
                    color: "#117BD4",
                    bg: "white",
                    textDecoration: "none",
                  }}
                >
                  {i.name}
                </Link>
              );
            })}
        </SimpleGrid>
      </VStack>

      <VStack w="100%" pt="40px" spacing="5px">
        <Text
          fontWeight={500}
          fontFamily="CeraPro"
          fontSize="16px"
          color="#98A6AD"
          w="100%"
          textAlign="left"
          pb="2px"
        >
          Cities we deliver to
        </Text>

        <SimpleGrid
          columns={[2, 9]}
          spacingX={[10, 12]}
          spacingY={1}
          placeSelf="flex-start"
        >
          {cityList
            ?.slice()
            .sort((a, b) => a.trim().localeCompare(b.trim()))
            .map((i) => {
              return (
                <Text
                  key={i}
                  fontWeight={500}
                  fontFamily="CeraPro"
                  fontSize="14px"
                  placeSelf="left"
                  color="#214151"
                  maxW="120px"
                >
                  {i}
                </Text>
              );
            })}
        </SimpleGrid>
      </VStack>

      <VStack w="100%" pt="40px" spacing="5px">
        <Text
          fontWeight={500}
          fontFamily="CeraPro"
          fontSize="16px"
          color="#98A6AD"
          w="100%"
          textAlign="left"
          pb="2px"
        >
          Company
        </Text>

        <Link
          href={`${process.env.NEXT_PUBLIC_URL}store/about-us`}
          fontWeight={500}
          fontFamily="CeraPro"
          fontSize="14px"
          color="#214151"
          w="100%"
          textAlign="left"
          textDecoration="none"
          _hover={{ color: "#117BD4", textDecoration: "none" }}
          _active={{ color: "#117BD4", textDecoration: "none" }}
          _focus={{ color: "#117BD4", textDecoration: "none" }}
        >
          FAQ's
        </Link>

        <Link
          href={`${process.env.NEXT_PUBLIC_BLOGS_URL}`}
          fontWeight={500}
          fontFamily="CeraPro"
          fontSize="14px"
          color="#214151"
          w="100%"
          textAlign="left"
          textDecoration="none"
          _hover={{ color: "#117BD4", textDecoration: "none" }}
          _active={{ color: "#117BD4", textDecoration: "none" }}
          _focus={{ color: "#117BD4", textDecoration: "none" }}
        >
          Blogs
        </Link>

        {/* <Text fontWeight={500} fontFamily='CeraPro' fontSize='14px' color='#214151' w='100%' textAlign='left'>
          Contact us
        </Text> */}
      </VStack>

      {/* <VStack w='100%' pt='40px'spacing='18px'>
        <Text fontWeight={500} fontFamily='CeraPro' fontSize='16px' color='#98A6AD' w='100%' textAlign='left' pb='2px'>
          Support
        </Text>
        <Text fontWeight={500} fontFamily='CeraPro' fontSize='14px' color='#214151' w='100%' textAlign='left'>
          Getting Started
        </Text>
        <Text fontWeight={500} fontFamily='CeraPro' fontSize='14px' color='#214151' w='100%' textAlign='left'>
          Help center
        </Text>
        <Text fontWeight={500} fontFamily='CeraPro' fontSize='14px' color='#214151' w='100%' textAlign='left'>
          Report a bug
        </Text>
        <Text fontWeight={500} fontFamily='CeraPro' fontSize='14px' color='#214151' w='100%' textAlign='left'>
          Chat support
        </Text>
      </VStack> */}

      <VStack w="100%" pt="40px" spacing="10px" pb={["40px", "88px"]}>
        <Text
          fontWeight={500}
          fontFamily="CeraPro"
          fontSize="16px"
          color="#98A6AD"
          w="100%"
          textAlign="left"
          pb="2px"
        >
          Contact us
        </Text>

        <HStack w="100%">
          <Icon as={HiOutlineMail} boxSize={6} color="#98A6AD" />
          <Text
            fontWeight={500}
            fontFamily="CeraPro"
            fontSize="14px"
            color="#214151"
            w="100%"
            textAlign="left"
          >
            <Link
              href="mailto:customer_service@thirst.com.ph"
              textDecoration="none"
            >
              customer_service@thirst.com.ph
            </Link>
          </Text>
        </HStack>

        <HStack w="100%">
          <Icon as={FiPhone} boxSize={6} color="#98A6AD" />
          <Text
            fontWeight={500}
            fontFamily="CeraPro"
            fontSize="14px"
            color="#214151"
            w="100%"
            textAlign="left"
          >
            <Link href="tel:09053320229" textDecoration="none">
              +63 905 332 0229
            </Link>
          </Text>
        </HStack>

        <HStack w="100%">
          <Icon
            as={HiOutlineLocationMarker}
            boxSize={7}
            color="#98A6AD"
            placeSelf="flex-start"
          />
          <Text
            fontWeight={500}
            fontFamily="CeraPro"
            fontSize="14px"
            color="#214151"
            w="100%"
            textAlign="left"
          >
            <Link
              href="https://www.google.com/maps/place/Thirst+-+Liquor+On+Demand/@14.5867041,121.0486268,17z/data=!3m1!4b1!4m6!3m5!1s0x3397c9d1284f0cf9:0xd2b591279c2763aa!8m2!3d14.5867041!4d121.0486268!16s%2Fg%2F11rhsw685p?authuser=0&hl=en&entry=ttu"
              textDecoration="none"
              target="_blank"
            >
              Twin Towers, Unit 411 4F Tower B, Wack Wack Rd, Mandaluyong, 1555
              Metro Manila
            </Link>
          </Text>
        </HStack>
      </VStack>

      <Divider />

      <Text
        fontSize="14px"
        fontWeight={500}
        fontFamily="CeraPro"
        color="#214151"
        pt="34px"
      >
        Copyright © 2024 Thirst Liquor on Demand
      </Text>

      <Wrap w="100%" pt="26px" justify="center">
        <WrapItem>
          <Text
            w="100%"
            whiteSpace="nowrap"
            fontSize="14px"
            fontWeight={500}
            fontFamily="CeraPro"
            color="#214151"
          >
            All Rights Reserved
          </Text>
        </WrapItem>

        <WrapItem>
          <Text
            textAlign="center"
            fontSize="14px"
            fontWeight={500}
            fontFamily="CeraPro"
            color="#214151"
          >
            |
          </Text>
        </WrapItem>

        <WrapItem>
          <Text
            fontSize="14px"
            as="button"
            onClick={() => router.push("/store/terms-and-conditions/")}
            fontWeight={500}
            fontFamily="CeraPro"
            color="#117BD4"
          >
            Terms and Conditions
          </Text>
        </WrapItem>

        <WrapItem>
          <Text
            w="100%"
            whiteSpace="nowrap"
            fontSize="14px"
            textAlign="center"
            fontWeight={500}
            fontFamily="CeraPro"
            color="#214151"
          >
            |
          </Text>
        </WrapItem>

        <WrapItem>
          <Text
            noOfLines={2}
            as="button"
            onClick={() => router.push("/store/privacy-policy/")}
            fontSize="14px"
            fontWeight={500}
            fontFamily="CeraPro"
            color="#117BD4"
            pb="36px"
          >
            Privacy Policy
          </Text>
        </WrapItem>
      </Wrap>
    </VStack>
  );
};

export default StoreFooter;
